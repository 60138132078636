.items:hover {
    background: orange;
    color: #fff;
    border-bottom: 1px solid orange;
    cursor: pointer;
}
.items2:hover {
    background: #f4f4f4;
    border-bottom: 1px solid orange;
    cursor: pointer;
}
.btn-actions:hover {
  color: orange !important;
}
.items:hover img {
  border: 1px solid #fff;
}
